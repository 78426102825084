import { Notice } from '@/entities/notice';
import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { commonHOC } from '../src/component';
import { loadPageItems, type StaticProps } from '../src/contentful/ssr';
import { HomepageTemplate, Page } from '../src/template';
import { HeliosTemplate } from '../src/template/heliosTemplate';
import { HomepageSlug } from '../src/types';

export const getServerSideProps: GetServerSideProps<StaticProps> = async (context) =>
	loadPageItems(HomepageSlug, context);

export const Home = (staticProps: InferGetServerSidePropsType<typeof getServerSideProps>) => {
	let children;
	switch (staticProps.content?.__typename) {
		case 'HomepageTemplate':
			children = <HomepageTemplate content={staticProps.content} />;
			break;
		case 'helios':
			children = <HeliosTemplate content={staticProps.content} />;
			break;
		default:
			children = <Notice text="Invalid content returned from CMS" />;
			break;
	}
	return <Page {...staticProps}>{children}</Page>;
};

export default commonHOC(Home, 'Home');
