import { Container, useTheme } from '@chakra-ui/react';
import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { ViewResolverDigitalHealth, type ViewResolverDigitalHealthProps } from '../component/ViewResolverDigitalHealth';

export type HeliosTemplateProps = {
	content: ViewResolverDigitalHealthProps;
	__typename?: 'helios';
};

export const HeliosTemplate: FunctionComponent<HeliosTemplateProps> = ({ content }) => {
	const theme = useTheme<Tokens>();
	// prevent overwriting font body by helios components
	useEffect(() => {
		const font = theme.fonts.body;
		const rootElement = document.querySelector(':root') as HTMLElement;
		// Set the value of variable --chakra-fonts-body to desired font
		rootElement.style.setProperty('--chakra-fonts-body', font);
	}, [theme.fonts.body]);

	return (
		<Container maxW={theme.breakpoints['2xl']} p={0}>
			<ViewResolverDigitalHealth {...content} />
		</Container>
	);
};
